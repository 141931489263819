<template>
  <div class="loading">
    <breeding-rhombus-spinner
      :animation-duration="1500"
      :size="60"
      color="#16b666"
    />
    <span>Carregando dados...</span>
  </div>
</template>

<script>
import { BreedingRhombusSpinner } from 'epic-spinners'

export default {
  components: { BreedingRhombusSpinner }
}
</script>

<style lang="sass" scoped>
.loading
  display: flex
  width: 100%
  height: calc(20rem)
  justify-content: center
  align-items: center
  flex-direction: column
  font-size: 1.5rem

span
  margin-top: 3rem
</style>
